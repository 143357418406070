import { TextField } from "@material-ui/core";
import React from "react";

export default function MailClientForm(props) {
  return (
    <div className="block">
      <p>Please Enter your Mail Credentials</p>
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-3">
            <TextField
              label="Email Address"
              value={props.data.email}
              name="email"
              fullWidth
              onChange={(e) => props.handleFormChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
            <TextField
              label="Password"
              type="password"
              value={props.data.password}
              name="password"
              fullWidth
              onChange={(e) => props.handleFormChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
            <TextField
              label="From Name"
              value={props.data.from_name}
              name="from_name"
              fullWidth
              onChange={(e) => props.handleFormChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
            <TextField
              label="From Address"
              value={props.data.from_address}
              name="from_address"
              fullWidth
              onChange={(e) => props.handleFormChange(e)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-4">
            <TextField
              label="Host"
              value={props.data.host}
              name="host"
              fullWidth
              helperText={
                "We have assumed this from your organization settings if specified"
              }
              onChange={(e) => props.handleFormChange(e)}
            />
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Port"
              value={props.data.port}
              name="port"
              fullWidth
              onChange={(e) => props.handleFormChange(e)}
              helperText={
                "We have assumed this from your organization settings if specified"
              }
            />
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Encryption"
              value={props.data.encryption}
              name="encryption"
              fullWidth
              onChange={(e) => props.handleFormChange(e)}
              helperText={
                "We have assumed this from your organization settings if specified"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
