import {
  MailerApi,
  EmailApi,
  AppHeaderComponent,
  AppButtonComponent,
} from "@unity/components";
import React, { useState, useEffect, useContext } from "react";
import MailClientForm from "../modules/mail/MailClientForm";
import { useHistory } from "react-router-dom";
import ApiLoader from "../common/ApiLoader";
export default function UpdateDetails(props) {
  const [data, setData] = useState(false);
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState({ statue: false });

  let history = useHistory();

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateCredentials = async () => {
    setLoading({ status: true, data: "Loading please wait..." });
    const res = await EmailApi.updateUserMailer(form);
    setLoading({ status: true, data: "Save Success please wait..." });
    setTimeout(() => {
      history.push("/emails/index");
    }, 4000);
  };
  return (
    <div>
      <AppHeaderComponent
        saveBtn={updateCredentials}
        context={props.context}
        name="emails"
        theme={props.context.theme}
      />
      <ApiLoader status={loading.status} data={loading.data} />
      <div style={{ paddingTop: 130 }}>
        <MailClientForm
          data={form}
          setData={setForm}
          handleFormChange={handleFormChange}
        />
      </div>
    </div>
  );
}
