import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Select, MenuItem, TextField, InputLabel } from "@material-ui/core";
import {
  CampaignApi,
  DynamicListApi,
  ClientsApi,
  TargetsApi,
} from "@unity/components";
import useDebounce from "./CustomHooks";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AttachEmailNotification({
  open,
  description,
  title,
  accept,
  cancel,
  datas,
  setOpen,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState(false);
  const [listItems, setListItems] = useState(false);
  const [responses, setResponses] = useState(false);
  const [campaignId, setCampaignId] = useState(false);
  const [responseId, setResponseId] = useState(false);
  const [newResponse, setNewResponse] = useState(false);
  const [singleResponse, setSingleResponse] = useState(false);
  const [singleResponseId, setSingleResponseId] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [dynamicList, setDynamicList] = useState(false);
  const [typedValue, setTypedValue] = useDebounce("", 1000);
  const [listCriteria, setListCriteria] = useState(false);
  const [clientsAuto, setClientsAuto] = useState(false);
  const [campaignResponses, setCampaginResponses] = useState([]);

  const [endPoint, setEndPoint] = useState("targets");

  const handleAccept = async () => {
    console.log("clicked");
    if (newResponse) {
      //create a new response
      //data["campaign_id"] = campaignId;
      //  await storeResponse(data);
      setOpen(false);
    } else {
      //TODO Slot into existing campaign response activity
      const newData = { ...data };

      newData["type"] = "Email";

      // newData["data"]["type"] = "Email";
      newData["subject"] = datas.subject;
      newData["body"] = datas.body;

      newData["response_id"] = responseId;
      newData["response_activity_id"] = singleResponseId;

      endPoint === "targets"
        ? (newData["customer_type"] = 1)
        : (newData["customer_type"] = 0);

      setData(newData);

      const res = await CampaignApi.saveResponse(newData);
      console.log("Saved DATA", res);

      setOpen(false);
    }
  };

  const handleOnChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleAutoComplete = (val) => {
    const newData = { ...data };
    endPoint === "targets"
      ? (newData["client_id"] = val.id)
      : (newData["client_id"] = val.customer_id);

    //val.id
    setData(newData);
  };

  const handleTypedValue = (value) => {
    setTypedValue(value);
    updateOption();
  };

  const updateOption = async () => {
    listCriteria["search"] = typedValue;
    if (endPoint === "clients") {
      const res2 = await ClientsApi.fuzzyFilterSearch(
        "clients",
        listCriteria.criteria
      );
      setClientsAuto(res2.data);
    } else if (endPoint === "targets") {
      const res = await TargetsApi.filterSetSearchTargets(
        listCriteria.criteria
      );
      console.log("Updated op targets", res);
      setClientsAuto(res.data);
    }
  };

  const handleCampaignSelect = async (id) => {
    setLoading(true);

    data["campaign_id"] = id;

    //Get the dynamic list
    //Fuzzy Search over it
    //Display the list back to the user
    //First option will NULL
    //If Null save the response as a new response
    //Else save it as a response to that response

    const res = await DynamicListApi.getDynamicListIndex();
    setDynamicList(res.data);

    const res2 = await CampaignApi.getResponsesIndexByCampaign(id);
    setCampaginResponses(res2.data);
  };

  const handleResponseSelect = async (id) => {
    /// const res = await getResponse(id);
    // setResponseId(id);
    //setSingleResponse(res.data);
  };

  const handleListChoice = async (id) => {
    // const res = await getListItem(id);
    // setListItems(res.data.data);

    const res = await DynamicListApi.getDynamicListSingle(id);
    res.data["search"] = "";
    setListCriteria(res.data);

    setEndPoint(res.data.list_type);
    if (res.data.list_type === "clients") {
      const res2 = await ClientsApi.fuzzyFilterSearch(endPoint, res.data);
      console.log("res2", res2);
      setClientsAuto(res2.data);
    } else if (res.data.list_type === "targets") {
      const listRes = await TargetsApi.filterSetSearchTargets(res.data);
      console.log("Targets, RES", listRes);
      setClientsAuto(listRes.data);
    }
  };

  const handleListItemSelect = async (id) => {
    const contactObj = listItems.find((e) => e.id === id);
    const newData = { ...data };
    newData["client_id"] = contactObj.client_id;
    setData(newData);
  };

  const handleDataChange = (e) => {
    const newData = { ...data };
    newData["data"][e.target.name] = e.target.value;
    setData(newData);
  };

  const handleRootChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const getCampaignData = async () => {
    const res = await CampaignApi.getCampaign();
    setCampaigns(res.data);
  };

  useEffect(() => {
    getCampaignData();
    updateOption();
  }, [typedValue]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Log Incomming Email
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Fill out notes from a email received, assign it to a campaign
            response activity.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            <InputLabel style={{ marginTop: 12 }}>Choose Campaign</InputLabel>
            <Select
              fullWidth
              onChange={(e) => handleCampaignSelect(e.target.value)}
              name="campaign"
            >
              {campaigns.map((item, key) => {
                return (
                  <MenuItem key={key} value={item.id}>
                    {item.campaign_name}
                  </MenuItem>
                );
              })}
            </Select>

            <InputLabel style={{ marginTop: 12 }}>
              Follow Up To Response
            </InputLabel>
            <Select
              fullWidth
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              name="response_parent_id"
              value={data.response_parent_id ? data.response_parent_id : ""}
            >
              <MenuItem value={null}>None</MenuItem>
              {campaignResponses.map((item, key) => {
                return (
                  <MenuItem key={key} value={item.id}>
                    {item.subject}
                  </MenuItem>
                );
              })}
            </Select>

            {dynamicList && (
              <>
                <InputLabel style={{ marginTop: 12 }}>
                  Choose Which List
                </InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => handleListChoice(e.target.value)}
                >
                  {dynamicList.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
              //TODO on log incomming call new response check need to get list of customers then make a post to new response route create
            )}

            {clientsAuto && (
              <>
                <InputLabel style={{ marginTop: 12 }}>
                  Choose Which Client
                </InputLabel>
                <Autocomplete
                  value={data.client_id}
                  options={clientsAuto}
                  name={"client_id"}
                  getOptionLabel={(item) =>
                    item.customer_name || item.company_name
                  }
                  onChange={(e, val) => handleAutoComplete(val)}
                  onInputChange={(e) => handleTypedValue(e.target.value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </>
              //TODO on log incomming call new response check need to get list of customers then make a post to new response route create
            )}

            {singleResponse && (
              <>
                <InputLabel style={{ marginTop: 12 }}>
                  Choose Response Activity
                </InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setSingleResponseId(e.target.value)}
                >
                  {singleResponse.activities.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
              //TODO on log incomming call new response check need to get list of customers then make a post to new response route create
            )}

            <TextField
              label="Activity Title"
              fullWidth
              multiline
              name="title"
              onChange={(e) => handleRootChange(e)}
            />
            <TextField
              label="Activity Description"
              fullWidth
              multiline
              name="description"
              onChange={(e) => handleRootChange(e)}
            />

            <>
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={newResponse}
                    onChange={(e) => setNewResponse(e.target.checked)}
                  /> 
                }
                label="Is new Response?"
              /> */}
            </>

            {listItems && (
              <>
                <InputLabel style={{ marginTop: 12 }}>
                  Choose Client from Your Target List
                </InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => handleListItemSelect(e.target.value)}
                >
                  {listItems.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <InputLabel style={{ paddingTop: 15 }}>
                  What caused the response ?
                </InputLabel>
                <TextField
                  fullWidth
                  name="subject"
                  label="Title"
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  name="body"
                  multiline
                  label="Description"
                  style={{ marginTop: 10 }}
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.value })
                  }
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancel()} color="secondary">
            Close
          </Button>
          <Button onClick={() => handleAccept()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
