import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
/* import {
  getMail,
  getMailCredentials,
  storeMailCredentials,
} from "../../api/MailClientApi"; */
import {
  MailerApi,
  EmailApi,
  AppHeaderComponent,
  AppButtonComponent,
} from "@unity/components";
import Loading from "../common/Loading";
import PageHeader from "../common/PageHeader";

import MailClient from "../modules/mail/MailClient";
import MailClientForm from "../modules/mail/MailClientForm";
import UpdateDetails from "./updateDetails";
export default function EmailIndex({ context, route, history }) {
  const [data, setData] = useState(false);
  const [form, setForm] = useState(false);

  console.log(context);

  const getMailSettings = () => {
    //const res = await MailerApi.getMailer();
    setForm({
      email: context.email,
      from_name: context.name,
      from_address: context.email,
      host: "",
      port: "",
      //encryption: res.data.encryption,
    });
  };

  const checkMailer = async () => {
    const res = await EmailApi.getUserMailer();
    if (!res.data) {
      getMailSettings();
    } else {
      setData({});
    }
  };

  console.log(data);

  const handleCredentialSave = async () => {
    const res = await EmailApi.saveUserMailer(form);
    window.location.reload();
  };

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateCredentials = async () => {
    const res = await EmailApi.updateUserMailer(form);
  };

  console.log(form);

  useEffect(() => {
    checkMailer();
  }, []);
  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          name="emails"
          theme={context.theme}
          addNew={() => (
            <Link to={"/emails/updateDetails"}>
              <AppButtonComponent
                text="Update Details"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
        />
        <div style={{ paddingTop: 150 }}>
          <MailClient />
        </div>
      </>
    );
  } else if (form) {
    return <UpdateDetails context={context} />;
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
}
