import React, { useEffect, useRef, useState } from "react";
//import { getMail } from "../../../api/MailClientApi";
import "./MailClient.css";
//import AttachEmailNotification from "../../common/AtachEmailNotification";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CheckIcon from "@material-ui/icons/Check";
import { Slider, IconButton, CircularProgress } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AttachEmailNotification from "../../common/AtachEmailNotification";
import {
  MailerApi,
  EmailApi,
  AppHeaderComponent,
  AppButtonComponent,
} from "@unity/components";
export default function MailClient() {
  const [data, setData] = useState(false);
  const [active, setActive] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ state: false, data: false });
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const getMessages = async (currentPage = null) => {
    setLoading(true);

    if (currentPage === null) {
      currentPage = page;
    }

    setPage(currentPage);

    const res = await EmailApi.getMail(currentPage);

    setData(res.data);
    if (res.pages && Number(res.pages) != NaN) {
      setMaxPage(res.pages + 1);
    }

    setLoading(false);
  };

  const handlePopup = (key) => {
    setOpen({ state: true, data: data[key] });
  };

  const handleAttach = async () => {};
  const handleClose = () => {
    setOpen({ ...open, state: false });
  };

  useEffect(() => {
    getMessages();
  }, [update]);

  return (
    <div className="container-fluid">
      <AttachEmailNotification
        open={open.state}
        accept={handleAttach}
        cancel={handleClose}
        setOpen={setOpen}
        datas={open.data}
        title="Attach email to campaign response"
      />
      <div className="row">
        <div className="col-lg-4">
          <p onClick={() => setUpdate(!update)}>
            {loading ? <CircularProgress /> : "Refresh"}
          </p>
          <div className="list-container">
            {data ? (
              data.map((mail, key) => {
                var avatar = mail.from.name.replace(" ", "+");
                return (
                  <div className="msg-container" onClick={() => setActive(key)}>
                    <div className="msg-date">{mail.received}</div>
                    <div className="msg-preview">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          <img
                            src={`https://eu.ui-avatars.com/api/?rounded=true&name=${avatar}&background=0BD4FF`}
                          />
                        </div>
                        <div style={{ flex: 11, paddingLeft: 10 }}>
                          <b>{mail.from.name}</b>
                          <p
                            style={{ margin: 0 }}
                            dangerouslySetInnerHTML={{ __html: mail.subject }}
                          />
                        </div>
                        {mail.saved ? (
                          <div className="action-btn">
                            <CheckIcon
                              style={{
                                fontSize: 35,
                                color: "white",
                                marginTop: "12",
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{ backgroundColor: "red" }}
                            className="action-btn"
                            onClick={() => handlePopup(key)}
                          >
                            <AttachFileIcon
                              style={{
                                fontSize: 35,
                                color: "white",
                                marginTop: "12",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Loading</p>
            )}
          </div>
        </div>
        <div className="col-lg-8">
          {active && (
            <div
              className="mail-container"
              dangerouslySetInnerHTML={{ __html: data[active].body }}
            ></div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-1">
          <IconButton
            disabled={page - 1 < 1 || loading}
            onClick={() => {
              getMessages(page - 1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-lg-10">
          <Slider
            defaultValue={1}
            valueLabelDisplay="on"
            marks
            min={1}
            max={maxPage}
            value={page}
            onChange={(e, value) => {
              setPage(value);
            }}
            onChangeCommitted={(e, value) => {
              getMessages(value);
            }}
            disabled={loading}
          />
        </div>
        <div className="col-lg-1">
          <IconButton
            disabled={page + 1 > maxPage || loading}
            onClick={() => {
              getMessages(page + 1);
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
