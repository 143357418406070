import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";

import EmailIndex from "./pages/index";
import UpdateDetails from "./pages/updateDetails";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.emails["A"] &&
                      props.auth.access.emails["R"] && (
                        <Route
                          path="/emails/index"
                          exact
                          component={(route) => (
                            <EmailIndex
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.emails["A"] &&
                      props.auth.access.emails["R"] && (
                        <Route
                          path="/emails/updateDetails"
                          exact
                          component={(route) => (
                            <UpdateDetails
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
